import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis} from "recharts";

function fmtMSS(s) {
    return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s
}

// The ProgressSummary component shows a summary of the lesson progression, currently including stats like
// elapsed time and current word count
const ProgressSummary = ({wordData, lesson, elapsed}) => {
    const wordTotalCount = wordData.reduce((r, v) => {
        return r + v.count
    }, 0)
    const wordTargetCount = lesson.targetTotal
    const wordPercentage = (wordTotalCount / wordTargetCount) * 100

    const elapsedInMinutes = fmtMSS(elapsed)
    // const targetDuration = fmtMSS(lesson.duration * 60)
    const durationPercentage = (elapsed / (lesson.duration * 60)) * 100

    // create shared data for word count total and current duration, like %
    const data = [{
        wordCountPerc: wordPercentage,
        durationPerc: durationPercentage,
        total: 100,
    }]
    return <div className={'progress-summary'}>
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                layout={'vertical'}
                data={data}
                margin={{top: 5, right: 5, bottom: 5, left: 5}}
            >
                <CartesianGrid strokeDasharray="3 3" horizontal={false}/>
                <XAxis type={'number'} dataKey={'total'} domain={[0, 100]} hide/>
                <YAxis type={'category'} dataKey={'wordCountPerc'} axisLine={false} tickLine={false} hide/>
                <Bar name={`${wordTotalCount}/${wordTargetCount} words`} dataKey="wordCountPerc" fill={'#fda95e'}
                     maxBarSize={20}/>
                <Bar name={`${elapsedInMinutes}/${lesson.duration} min`} dataKey="durationPerc" fill={'#4ab7b6'}
                     maxBarSize={20}/>
                <Legend verticalAlign={'top'} wrapperStyle={{top: 0}}/>
            </BarChart>
        </ResponsiveContainer>
    </div>
}


function ProgressSummaryAlt({wordData, lesson, elapsed}) {
    const totalCount = wordData.reduce((r, v) => {
        return r + v.count
    }, 0)

    // const elapsedInMinutes = fmtMSS(elapsed)
    const remaining = lesson.duration * 60 - elapsed
    const remainingInMinutes = fmtMSS(remaining)

    return <div className={'progress-summary-alt'}>
        <div className={'progress-stat'}>
            <div className={'value'}>{`${totalCount} / ${lesson.targetTotal}`}</div>
            <div className={'value-label'}>words</div>
        </div>
        {/*<div className={'progress-stat'}>*/}
        {/*    <div className={'value'}>{elapsedInMinutes}</div>*/}
        {/*    <div className={'value-label'}>elapsed</div>*/}
        {/*</div>*/}
        <div className={'progress-stat'}>
            <div className={'value'}>{remaining > 0 ? remainingInMinutes : '--:--'}</div>
            <div className={'value-label'}>remaining</div>
        </div>
    </div>
}


export default ProgressSummary

// keywords is a [][]string where the keyword and permutations for each word are in the same position of the first array
// for example [][]string{
//   { "dog", "dogs", "dog's" },
//   { "cat", "cats" },
// }
import { barColors } from './WordCounts'
import { useEffect, useRef, useState } from 'react'

const Transcript = ({ keywords = [], transcript }) => {
  const [highlightWords, setHighlightWords] = useState(true)
  const [autoScroll, setAutoScroll] = useState(true)
  const bottomRef = useRef(null)

  useEffect(() => {
    if (autoScroll) {
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [transcript, autoScroll])


  let temp = transcript

  {
    highlightWords && keywords.forEach((keywordPermutations, idx) => {
      keywordPermutations.forEach(keyword => {
        temp = temp.replace(new RegExp(`\\b${keyword}\\b`, 'ig'), (original) => {
          let word = keyword
          if (startsWithCapital(original)) {
            word = capitalise(word)
          }
          return wrapKeywordWithHTML(original, word, barColors[idx])
        })
      })
    })
  }

  return <div className={'transcript'}>
    <div className={'controls'}>
      <div className={'autoscroll'}>
        <span className={'label'}>Auto scroll</span>
        <label className="switch">
          <input
            type="checkbox"
            onChange={(e) => setAutoScroll(e.target.checked)}
            checked={autoScroll}/>
          <span className="slider round"></span>
        </label>
      </div>
      <div className={'highlight'}>
        <span className={'label'}>Highlight words</span>
        <label className="switch">
          <input
            type="checkbox"
            onChange={(e) => setHighlightWords(e.target.checked)}
            checked={highlightWords}/>
          <span className="slider round"></span>
        </label>
      </div>
    </div>
    <div className={'content'}>
      <div className={'text'} dangerouslySetInnerHTML={{ __html: temp }}/>
      <div ref={bottomRef}/>
    </div>
  </div>
}

function startsWithCapital(word) {
  return word.charAt(0) === word.charAt(0).toUpperCase()
}

function capitalise(word) {
  if (word.length === 1) {
    return word.charAt(0)
  }
  if (word.length >= 2) {
    return word.charAt(0).toUpperCase() + word.slice(1)
  }
  return word
}

function wrapKeywordWithHTML(original, keyword, colour) {
  return `<span style="font-weight: bold; color: ${colour}; text-decoration: underline">${keyword}</span>`
}

export default Transcript

import logo from './logo.webp'
import { useState } from "react"
import { endSession, postChunk, startSession, updateWordCount } from "./api"
import { wordCount } from "./wordCount"
import useInterval from "./interval"
import WordCounts from "./WordCounts"
import LessonSummary from "./LessonSummary"
import ProgressSummary from "./ProgressSummary"
import LessonSelection from "./LessonSelection"
import WordSelection from "./WordSelection"
import { lessons } from "./lessons"
import AudioDelay from "./AudioDelay"
import Transcript from './Transcript'
import nlp from 'compromise'

let transcript = ''

const App = () => {
  const [lessonIdx, setLessonIdx] = useState(-1)
  const [words, setWords] = useState([])
  const [keywords, setKeywords] = useState([])
  const [isRecording, setRecording] = useState(false)
  const [hasRecording, setHasRecording] = useState(false)
  const [rec, setRec] = useState(null)
  const [stream, setStream] = useState(null)
  const [liveTranscript, setLiveTranscript] = useState('')
  const [elapsed, setElapsed] = useState(0)
  const [error, setError] = useState('')
  const [audioDelay, setAudioDelay] = useState('5s')
  const [audioDelayInMillis, setAudioDelayInMillis] = useState(5000)
  const [sessionId, setSessionId] = useState('')

  useInterval(() => {
    const lesson = lessons[lessonIdx]
    const endTimeInSecs = lesson.duration * 60
    if (elapsed >= endTimeInSecs) {
      stopRecording()
    } else {
      setElapsed(elapsed + 1)
    }
  }, 1000, isRecording)

  useInterval(() => {
    // console.log("restarting recorder")
    if (rec != null) {
      if (rec.state !== 'inactive') {
        rec.stop()
      }
      rec.start()
    }
  }, audioDelayInMillis, isRecording)

  useInterval(() => {
    // whilst recording we want to periodically send the stats to the backend
    // these should in theory be updated at the end of the session but if we don't get that far for some reason
    // at least we'll have partial results.
    // [{"count": 3, "word": "dog"}, {"count": 0, "word": " cat "}]
    Promise.all(wordData.map(wd => {
      const word = wd.word.toLowerCase().trim()
      return updateWordCount({
        sessionID: sessionId,
        word: word,
        count: wd.count
      })
    })).then(() => Promise.resolve())
  }, 30_000, isRecording && sessionId !== '')

  function lessonChanged(newLessonIdx) {
    // set new lesson
    setLessonIdx(newLessonIdx)
    // update words - just clear for now can do some removal add to existing later
    const newLesson = lessons[newLessonIdx]
    const numWords = newLesson?.words || 0
    const newWords = Array(numWords).fill().map(() => '')
    setWords(newWords)
    setKeywords(newWords.map(w => {
      return [w]
    }))
  }

  function updateWords(newWords) {
    const wordsMap = newWords.map(w => w.trim())
    setWords(wordsMap)
    setKeywords(wordsMap.map(w => {
      const doc = nlp(w)
      const variations = new Set()
      variations.add(w)
      const nouns = doc.nouns()
      if (nouns.length !== 0) {
        variations.add(nouns.toSingular().text()).add(nouns.toPlural().text())
      }
      const verbs = doc.verbs()
      if (verbs.length !== 0) {
        variations
          .add(verbs.toFutureTense().text().indexOf(" ") !== -1 ? w : verbs.toFutureTense().text())
          .add(verbs.toPastTense().text().indexOf(" ") !== -1 ? w : verbs.toPastTense().text())
          .add(verbs.toPresentTense().text().indexOf(" ") !== -1 ? w : verbs.toPresentTense().text())
      }
      return Array.from(variations)
    }))
  }

  function audioDelayChanged(newDelay) {
    setAudioDelay(newDelay)
    let multiplier = 1000 // default to seconds
    if (newDelay.endsWith('m')) { // minutes
      multiplier = 60000
    }

    const delay = parseInt(newDelay.substring(0, newDelay.length - 1))
    if (!isNaN(delay)) {
      setAudioDelayInMillis(delay * multiplier)
    }
  }

  async function startRecording() {
    setError('')
    if (navigator.mediaDevices.getUserMedia) {
      // console.debug("start recording")
      try {
        const lesson = lessonIdx !== -1 ? lessons[lessonIdx] : {}
        const sessId = await startSession({
          description: `${lesson.duration}m, ${lesson.words} words, ${lesson.dosePerWord} doses`,
          duration: lesson.duration,
          dosePerWord: lesson.dosePerWord,
          words: words.join(','),
          startedAt: new Date().toISOString()
        })
        setSessionId(sessId)
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
        setLiveTranscript("")
        transcript = ''
        setRecording(true)
        setHasRecording(true)
        setStream(stream)

        const recorder = new MediaRecorder(stream)
        recorder.start()
        recorder.ondataavailable = onDataAvailable
        setRec(recorder)
        recorder.onstop = async e => {
          await postBlob()
        }
        // start countdown timer
        setElapsed(0)
      } catch (err) {
        if (err.name === "NotFoundError" || err.name === "DevicesNotFoundError") {
          setError("A microphone device, could not be found!")
        } else if (err.name === "NotReadableError" || err.name === "TrackStartError") {
          setError("The microphone is already in use!")
        } else if (err.name === "OverconstrainedError" || err.name === "ConstraintNotSatisfiedError") {
          setError("Constraints could not be satisfied")
        } else if (err.name === "NotAllowedError" || err.name === "PermissionDeniedError") {
          setError("Permission to use the microphone has been denied.")
        } else {
          console.error(err)
          if (err.message != null) {
            setError("Error starting session. " + err.message)
          } else {
            setError("Error starting session.")
          }
        }
      }
    }
  }

  let chunks = []

  function onDataAvailable(e) {
    chunks.push(e.data)
  }

  async function postBlob() {
    if (chunks && chunks.length !== 0) {
      const blob = new Blob(chunks, { type: "audio/webm" })
      chunks = []
      const resp = await postChunk(blob)
      console.log(resp)
      transcript = transcript.concat(resp.text)
      setLiveTranscript(transcript)
    }
  }

  async function stopRecording() {
    // console.log("stop recording")
    setRecording(false)
    if (rec != null && rec.state !== 'inactive') {
      rec.stop()
    } else {
      console.warn("recorder is null. not stopping")
    }
    if (stream != null) {
      const tracks = stream.getTracks()
      tracks.forEach(track => {
        track.stop()
      })
    } else {
      console.warn("stream is null. not stopping")
    }
    await endSession({ sessionID: sessionId, endedAt: new Date().toISOString(), transcript })
  }

  function resetRecording() {
    setRecording(false)
    setLessonIdx(-1)
    setWords([])
    setKeywords([])
    setHasRecording(false)
    setRec(null)
    setStream(null)
    setLiveTranscript('')
    setElapsed(0)
  }

  const lesson = (isRecording || hasRecording) && lessonIdx !== -1 ? lessons[lessonIdx] : {}
  const wordData = wordCount(words, liveTranscript, keywords)
  const notAllWordsEntered = words.length === 0 || words.filter(w => w == null || w.length === 0).length !== 0
  const startButtonDisabled = lessonIdx === -1 || notAllWordsEntered || isRecording

  return (
    <div className="app">
      <div className={'header-select'}>
        <header className="app-header">
          <img src={logo} className="app-logo" alt="logo"/>
          <h3 className={'app-name'}><span className={'ww-orange'}>Wombat</span><span
            className={'ww-blue'}>Words</span></h3>
        </header>
        <div className={'main-content'}>
          {isRecording || hasRecording ? <div className={'summaries'}>
            <LessonSummary lesson={lesson}/>
            <ProgressSummary lesson={lesson} wordData={wordData} elapsed={elapsed}/>
            <WordCounts wordData={wordData} lesson={lesson}/>
          </div> : <div className={'selection'}>
            <LessonSelection lessonIdx={lessonIdx} onLessonChange={lessonChanged}/>
            <WordSelection words={words} setWords={updateWords}/>
            <AudioDelay audioDelay={audioDelay} onAudioDelayChange={audioDelayChanged}/>
          </div>
          }

          {error && <div className={'error-msg'}>{error}</div>}

          <div className={'buttons'}>
            {isRecording
              ? <button onClick={stopRecording}>Stop</button>
              : hasRecording
                ? <button onClick={resetRecording}>Reset</button>
                : <button disabled={startButtonDisabled} onClick={startRecording}>Start</button>}
            {hasRecording && !isRecording && <a target={'_blank'} rel="noreferrer noopener"
                                                href={`mailto:research@neurabuild.com?subject=Shared lesson ${sessionId}&body=Session Id: ${sessionId}%0D%0ADescription: ${lesson.duration}m, ${lesson.words} words, ${lesson.dosePerWord} doses%0D%0AWords: ${wordData.map(wd => wd.word + ' (' + wd.count + ')')}%0D%0ATranscript: ${liveTranscript}`}>
              <button>Share</button>
            </a>}
          </div>
        </div>
      </div>
      <br/>
      <div className={"transcript-section"}>
        {(isRecording || hasRecording) && liveTranscript.length !== 0 &&
          <Transcript transcript={liveTranscript} keywords={keywords}/>}
      </div>
    </div>
  )
}

export default App

// the audio delays we support and show to the user
export const audioDelays = [
    '1s',
    '5s',
    '10s',
    '30s',
    '45s',
    '1m',
    '2m',
    '3m',
    '4m',
    '5m',
    '10m',
]
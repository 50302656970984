import {Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, XAxis, YAxis} from "recharts";

// the colours used in the bar graph for the words - should always have enough to cover the max
// number of words in a lesson/session (currently 6)
export const barColors = ["#53C8AC", "#AAD684", "#FBF87D", "#FCC66E", "#F298B1", "#9876B1"]

// a component to show the individual word counts during a lesson/session
const WordCounts = ({wordData, lesson}) => {
    return <div className={'word-counts'}>
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                layout={'vertical'}
                data={wordData}
                margin={{top: 5, right: 5, bottom: 5, left: 5}}
            >
                <CartesianGrid strokeDasharray="3 3" horizontal={false}/>
                <XAxis type={'number'} dataKey={'count'} domain={[0, lesson.dosePerWord]} hide/>
                <YAxis yAxisId={0} type={'category'} dataKey={'word'} axisLine={false} tickLine={false}/>
                <YAxis yAxisId={1} type={'category'} dataKey={'count'} axisLine={false} tickLine={false}
                       orientation={"right"}/>
                <Bar dataKey="count">
                    {
                        wordData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={barColors[index % 20]}/>
                        ))
                    }
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    </div>
}


export default WordCounts

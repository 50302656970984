// A component to show a summary of the selected lesson/session

const LessonSummary = ({lesson}) => {
    const wordsPerMin = Math.round(lesson.targetTotal / lesson.duration * 10) / 10
    return (<div className={'lesson-summary'}>
        <div className={'lesson-stat'}>
            <div className={'value'}>{`${lesson.duration} m`}</div>
            <div className={'value-label'}>duration</div>
        </div>
        <div className={'lesson-stat'}>
            <div className={'value'}>{wordsPerMin}</div>
            <div className={'value-label'}>words/min</div>
        </div>
        <div className={'lesson-stat'}>
            <div className={'value'}>{lesson.dosePerWord}</div>
            <div className={'value-label'}>mentions each</div>
        </div>
    </div>)
}

export default LessonSummary
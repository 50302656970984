export const API_BASE_URL =
    window.location.hostname === 'localhost'
        ? 'http://localhost:8080'
        : `https://${window.location.hostname}`

export const postChunk = (blob) =>
    fetch(`${API_BASE_URL}/api/transcribe`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            Accept: 'application/json',
        },
        body: blob,
    }).then((r) => {
        if (r.status === 200) {
            return r.json()
        }
        throw new Error("error transcribing")
    })

export const startSession = ({ description, duration, dosePerWord, words, startedAt}) => {
  return fetch(`${API_BASE_URL}/api/session`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
    },
    body: JSON.stringify({
      description,
      duration,
      dosePerWord,
      words,
      startedAt,
    }),
  }).then((r) => {
    if (r.status === 200) {
      return r.json()
    }
    throw new Error("error starting session")
  })
}

export const updateWordCount = ({ sessionID, word, count }) => {
  return fetch(`${API_BASE_URL}/api/session/${encodeURIComponent(sessionID)}/word/${encodeURIComponent(word)}/count/${encodeURIComponent(count)}`, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
    },
  }).then((r) => {
    if (r.status === 200) {
      return r.text()
    }
    throw new Error("error setting word count")
  })
}

export const endSession = ({ sessionID, endedAt, transcript }) => {
  return fetch(`${API_BASE_URL}/api/session/${encodeURIComponent(sessionID)}`, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
    },
    body: JSON.stringify({
      endedAt,
      transcript,
    })
  }).then((r) => {
    if (r.status === 200) {
      return r.text()
    }
    throw new Error("error ending session")
  })
}


// the logic to determine if a selected word has been said in the transcription
export const wordCount = (words = [], transcription = '', keywords = []) => {
  const loweredWords = words.map(w => w.toLowerCase().trim())
  const trans = transcription.toLowerCase().trim().replace(/[^A-Za-z0-9]+/g, " ")


  if (words.length !== keywords.length) {
    console.error('error: words and keywords have different lengths.', words.length, keywords.length)
    throw new Error('words and keywords have different lengths')
  }
  // create a mapping from keyword to the word it should represent for e.g. Map({dogs, dog}, {doggo, dog}, {dog, dog})
  const wordsMap = keywords.reduce((r, words, idx) => {
    words.forEach(w => {
      r.set(w.toLowerCase().trim(), loweredWords[idx])
    })
    return r
  }, new Map())

  const initialWordCount = Array.from(wordsMap.keys()).reduce((r, v) => {
    if (!r.has(v)) {
      r.set(v, 0)
    }
    return r
  }, new Map())

  const wordCounts = trans.split(' ').reduce((r, v, idx) => {
    if (r.has(v)) {
      let count = r.get(v)
      r.set(v, count + 1)
    }
    return r
  }, initialWordCount)

  // now map from all permutations of words back to the single word, incrementing the count as we go
  const counts = Array.from(wordCounts.entries()).reduce((r,entry) => {
    const keyword = entry[0]
    const count = entry[1]
    const mainWord = wordsMap.get(keyword)

    if (r.has(mainWord)) {
      const currentCount = r.get(mainWord)
      r.set(mainWord, currentCount + count)
    } else {
      r.set(mainWord, count)
    }
    return r
  }, new Map())

  return words.map((word) => {
    const count = counts.get(word.toLowerCase().trim()) || 0
    return {
      word: word,
      count: count,
    }
  })
}

// the lessons/sessions shown to user
export const lessons = [
    // {words: 1, duration: 0.2, dosePerWord: 5, targetTotal: 5}, // useful for testing
    {words: 3, duration: 15, dosePerWord: 45, targetTotal: 135},
    {words: 3, duration: 30, dosePerWord: 90, targetTotal: 270},
    {words: 4, duration: 30, dosePerWord: 68, targetTotal: 272},
    {words: 5, duration: 30, dosePerWord: 54, targetTotal: 270},
    {words: 6, duration: 30, dosePerWord: 45, targetTotal: 270},
    {words: 5, duration: 45, dosePerWord: 81, targetTotal: 405},
    {words: 6, duration: 45, dosePerWord: 68, targetTotal: 408},
]

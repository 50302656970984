import {lessons} from "./lessons";


// A component to select a lesson/session
const LessonSelection = ({lessonIdx, onLessonChange}) => {
    return (
        <div className={"lesson-selection"}>
            <select value={lessonIdx || ''} onChange={(e) => onLessonChange(e.target.value)}>
                {lessonIdx === -1 && <option key={''} value={-1} disabled={true}>Select a session</option>}
                {lessons.map((l, idx) => <option key={idx}
                                                 value={idx}>{`${l.duration}m, ${l.words} words, ${l.dosePerWord} doses`}</option>)}
            </select>
        </div>
    )
}

export default LessonSelection
import {audioDelays} from "./audioDelays";
import {useState} from "react";

// A component to allow the user to select an audio delay time period, which determines how frequently audio is sent to
// the server for transcribing
const AudioDelay = ({audioDelay, onAudioDelayChange}) => {
    const [show, setShow] = useState(false)
    return (
        <div className={"audio-delay"}>
            <div className={'title popup'} onClick={() => setShow(!show)}>Audio delay <span className={'in-circle'}>?</span>
                <span className={`popup-text  ${show ? 'show' : ''}`}>During a session the audio is recorded on the
                    browser in chunks and then sent to the server for transcribing, this controls the size of the audio
                    chunks. Longer chunks may help with accuracy but there will be no feedback on the webpage until the
                    chunk has been transcribed.</span>
            </div>
            <select value={audioDelay} onChange={(e) => onAudioDelayChange(e.target.value)}>
                {audioDelays.map((delay, idx) => <option key={idx} value={delay}>{delay}</option>)}
            </select>
        </div>
    )
}

export default AudioDelay
// A component to enter the words for the selected session/lesson
const WordSelection = ({words, setWords}) => {
    function updateWords(idx, newVal) {
        setWords(words.map((w, i) => i == idx ? newVal : w))
    }

    return (<div className={'word-selection'}>
        {words.map((word, idx) => {
            return <div key={idx} className={'word'}>
                <input type={'text'} value={word} onChange={(e) => updateWords(idx, e.target.value)}/>
            </div>
        })}
    </div>)
}


export default WordSelection